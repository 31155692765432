import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class ClienteService {
  async getClientesAll() {
    const clientes = await fetchWrapper.get(`${ruta}/cliente`);
    return clientes;
  }
  async getClientes(parametros) {
    const queryParams = parametros
      ? Object.keys(parametros)
          .map(
            (k) =>
              encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
          )
          .join("&")
      : "";
    const clientes = await fetchWrapper.get(
      `${ruta}/cliente-todos?` + queryParams
    );
    return clientes;
  }

  async sendClienteNuevo(cliente) {
    const nuevoCliente = await fetchWrapper.post(`${ruta}/cliente`, cliente);
    return nuevoCliente;
  }
  async sendClienteFacturaNuevo(cliente_factura) {
    const nuevoClienteFactura = await fetchWrapper.post(
      `${ruta}/cliente-factura`,
      cliente_factura
    );
    return nuevoClienteFactura;
  }
  async deleteCliente(cliente) {
    const clienteEliminado = await fetchWrapper.delete(
      `${ruta}/cliente/` + cliente.id
    );
    return clienteEliminado;
  }
  async updatedCliente(cliente) {
    const clienteActualizado = await fetchWrapper.put(
      `${ruta}/cliente/` + cliente.id,
      cliente
    );
    return clienteActualizado;
  }

  async showCliente(cliente) {
    const clienteShow = await fetchWrapper.get(`${ruta}/cliente/` + cliente);
    return clienteShow;
  }

  async filtrarClientes(datos) {
    return await fetchWrapper.post(`${ruta}/filtros_cliente`, datos);
  }

  async showClienteVentas(cliente) {
    return await fetchWrapper.get(`${ruta}/cliente_ventas/` + cliente);
  }

  async generarPDF(datos) {
    return await fetchWrapper.postPdf(
      `${ruta}/reporte/cliente/imprimir`,
      datos
    );
  }

  async exportarClientes(datos) {
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/clientes-export`,
      datos
    );
    return exportado;
  }
  async buscarXNombre(datos) {
    return await fetchWrapper.post(`${ruta}/cliente/buscar`, datos);
  }
  async buscarXNombreConFactura(datos) {
    return await fetchWrapper.post(`${ruta}/cliente/buscar/factura`, datos);
  }

  async buscarClientePorNit(ci_nit) {
    return await fetchWrapper.post(`${ruta}/cliente/buscar/nit`, ci_nit);
  }

  async cargarPagoClientes(datos) {
    return await fetchWrapper.post(`${ruta}/pago_cliente`, datos);
  }
  async updatedClienteFactura(cliente_factura) {
    const clienteActualizado = await fetchWrapper.put(
      `${ruta}/cliente-factura/` + cliente_factura.id,
      cliente_factura
    );
    return clienteActualizado;
  }
  async getClientesFacturas(parametros) {
    const queryParams = parametros
      ? Object.keys(parametros)
          .map(
            (k) =>
              encodeURIComponent(k) + "=" + encodeURIComponent(parametros[k])
          )
          .join("&")
      : "";
    const clientes_facturas = await fetchWrapper.get(
      `${ruta}/cliente-facturas-todos?` + queryParams
    );
    return clientes_facturas;
  }
  async deleteClienteFactura(cliente) {
    const clienteEliminado = await fetchWrapper.delete(
      `${ruta}/cliente-factura/` + cliente.id
    );
    return clienteEliminado;
  }

  async datos_necesarios_crear_cliente() {
    return await fetchWrapper.post(
      `${ruta}/cliente/datos_necesarios_crear_cliente`
    );
  }

  async buscarClienteProforma(datos) {
    return await fetchWrapper.post(
      `${ruta}/cliente/buscar_cliente_proforma`,
      datos
    );
  }
  async filtrarCumpleanios(datos) {
    return await fetchWrapper.post(`${ruta}/show_cumpleanios`, datos);
  }
  async obtenerCumpleaniosHoyManana() {
    return await fetchWrapper.get(`${ruta}/obtener_cumpleaneros_hoy_y_manana`);
  }
  async obtenerCumpleaniosMes(mes) {
    return await fetchWrapper.get(`${ruta}/obtener_cumpleaneros_mes/${mes}`);
  }

  async enviarFelicitationWhatsapp(datos) {
    return await fetchWrapper.post(
      `${ruta}/enviar_felicitacion_whatsapp`,
      datos
    );
  }
  async importarMercadoCliente(datos) {
    return await fetchWrapper.postFiles(`${ruta}/cliente-import`, datos);
  }
}
